import React, { useState, useEffect } from "react";
import {
    HashRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useRouteMatch,
    useLocation,
    useHistory
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf';
import zoom from "../../icons_pierreangot/add.png";
import unzoom from "../../icons_pierreangot/minus.png";
import previous from "../../icons_pierreangot/back.png";
import next from "../../icons_pierreangot/next.png";
import downloadIcon from "../../icons_pierreangot/file.png";
import close from "../../icons_pierreangot/cancel.png";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function Displayer(props) {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [scale, setScale] = useState(1);
    let location = useLocation();
    let query = new URLSearchParams(location.search);
    let file = query.get("file");
    let ele;
    let pos;
    console.log(`displaypopup: ${props.displayPopUp}`)

    useEffect(() => {
    }, []);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1)
    }

    function nextPage() {
        changePage(1)
    }

    function upScale() {
        setScale((prev) => prev + 0.2)
    }

    function downScale() {
        setScale((prev) => prev - 0.2)
    }

    function downLoadLink() {
        document.getElementById("download-link").click();
    }

    const pages = <Page pageNumber={pageNumber}
    scale={scale}
    height={document.documentElement.clientHeight * 1} 
        />
    

    return (
        <div className="display-wrapper">
            {props.displayPopUp && <div style={{position:"absolute", backgroundColor: "white", height: "100vh", widows: "100vw", zIndex: "1000", margin:"0", paddingLeft: "3vw", paddingRight: "3vw"}} onClick={() => props.setDisplayPopUp()}>
                <h2>L'affichage des partitions A4 sera meilleur si l'appareil est tenu verticalement, pour les A3, horizontalement</h2>
                <h2>Toucher l'écran pour faire disparaître ce message</h2>
                <h2 style={{marginTop: "10vh"}}>The display of A4 partitions will be better if the device is held vertically, for A3, horizontally</h2>
                <h2> Touch the screen to make this message disappear </h2>
            </div>}
            <div id={`${props.format}doc-wrapper`} className={`${props.format}doc-wrapper`} style={{marginLeft: "0", width: "100vw", height: "100vh", marginTop: "0", overflow: "auto", backgroundColor: "transparent"}}>
                <Document
                    file={file}
                    onLoadSuccess={onDocumentLoadSuccess}
                    className="tabl-doc"
                    error="Failure to load / Erreur de chargement"
                    loading={
                        <h1 id="loading">Loading / Chargement</h1>
                    }
                    >
                    {pages}
                </Document>
            </div>
            <button id={`${props.format}close`} onClick={() => window.history.back()}>
                <img src={close} id="close-img" height="32px" />
            </button>
            <a
                id="download-link"
                className="download-link"
                download={file}
                href={file}
            ></a>
            <p id="page-counter" style={{color: "white", mixBlendMode: "difference"}}>
                Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
            </p>
            <div id={`${props.format}btn-container-left`} style={{height: "240px"}}>
                <button
                    className={`${props.format}doc-nav-button previous-btn`} 
                    type="button"
                    disabled={pageNumber <= 1}
                    onClick={previousPage}
                    style={{height: "65px", width: "50px"}}
                >
                    <img src={previous} />
                </button>
                <button
                    className={`${props.format}doc-nav-button next-btn`} 
                    type="button"
                    disabled={pageNumber >= numPages}
                    onClick={nextPage}
                    style={{height: "65px", width: "50px"}}

                >
                    <img src={next} />
                </button>
                <button
                    className={`${props.format}doc-nav-button download-button`} 
                    type="button"
                    onClick={downLoadLink}
                    style={{height: "65px", width: "50px"}}

                >


                    <img src={downloadIcon} />
                </button>
            </div>
        </div>
    );
}

export default Displayer;