const catalog = {
    fr: <div className="catalog">
    <p>Classement du catalogue par instrumentation :</p>
    <p>2 Pianos :Opus 20</p>
    <p>2 Pianos et ensemble instrumental : Opus 28</p>
    <p>2 Pianos et percussions : Opus 35</p>
    <p>Basson et orchestre de chambre : Opus 3</p>
    <p>Basson seul : Opus 8 / Opus 11 /Opus 12 /Opus 15 /Opus 21</p>
    <p>Basson et piano : Opus 9 / Opus 11 /Opus 14 /Opus 32</p>
    <p>Clarinette seule : Opus 29</p>
    <p>Clarinette et basson : Opus 32</p>
    <p>Clarinette et piano : Opus 9 /Opus 24 /Opus 27</p>
    <p>Clarinette / Basson et piano : Opus 32 / opus 44</p>
    <p>Clarinette / piano / quintette à corde et petite percussion : Opus 31</p>
    <p>Cor en Fa et piano : Opus 42</p>
    <p>Duo de basson ou basson violoncelle : Duo de l'opus 2</p>
    <p>Ensemble instrumental : Opus 1</p>
    <p>Ensemble instrumental : Cordes / vents / percussions / piano : Opus 46</p>
    <p>Ensemble instrumental : Quintette à vents / percussions / piano à 4 mains / trio cuivre / Saxophone</p>
    <p>Mi b : Opus 52</p>
    <p>Ensemble de clarinette /percussion/ récitant : Opus 25</p>
    <p>Ensemble à corde et piano : Opus 34</p>
    <p>Flûte seule : Opus 17</p>
    <p>Hautbois et piano : Opus 21 / Opus 33</p>
    <p>Hautbois / Clarinette Sib /Violoncelle et piano : Opus 32</p>
    <p>Orchestre symphonique : Opus 13 /Opus 23 /Opus 26</p>
    <p>Orchestre réduit : Opus 54</p>
    <p>Orchestre à cordes et harpe : Opus 19</p>
    <p>Orchestre à cordes et vents :Opus 19</p>
    <p>Orchestre à cordes et 5 percussions : Opus 45</p>
    <p>Piano seul : Opus 5 /Opus 6 /Opus 7/Opus 10 /Opus 15 /opus 37 /Opus 40 / Opus 50 / Opus 53 /</p>
    <p>Opus 56</p>
    <p>Piano et chant : Opus 4 / Opus 41</p>
    <p>Piano et trompette : Opus 20</p>
    <p>Piano soliste percussions et orchestre à corde : Opus 50</p>
    <p>Quatuor à corde : Opus 32 / Opus 51</p>
    <p>Quatuor, Violon /Alto /Violoncelle / Piano : Opus 34</p>
    <p>Quatuor, Violon /Alto /Violoncelle / Piano : Opus 34</p>
    <p>Quatuor, Cl/Bn/Cor/ Piano : Opus 55</p>
    <p>Quintette à vent : Opus 18</p>
    <p>Quintette à vent et piano: Opus 18 /Opus 52</p>
    <p>Quintette de cuivre : Opus 43 (Deux n°)</p>
    <p>Saxophone soprano : Opus 33</p>
    <p>Saxophone ténor Si b : Opus 20</p>
    <p>Trio d'anches : Trio de l'opus 2</p>
    <p>Violon seul : Opus 30</p>
    <p>Violon alto et harpe : Opus 38</p>
    <p>Trio violon /violoncelle /Piano : Opus 16 / Opus 49</p>
    <p>Trompette seule :Opus 22</p>
    <p>Violoncelle et piano : Opus 9 /Opus 16 / Opus 32 / Opus 38 / Opus 39</p>
    <p>Violoncelle seul : Opus 15 / Opus 17 /Opus 47</p>
    <p>Octuor cl/c/bn/ 2v/alt/vlc/cb : Opus 47</p>
    </div>,
    en: <div className="catalog">
    <p>Catalog classification by instrumentation:</p>
    <p>2 Pianos: Opus 20</p>
    <p>2 Pianos and instrumental ensemble: Opus 28</p>
    <p>2 Pianos and percussions: Opus 35</p>
    <p>Bassoon and chamber orchestra: Opus 3</p>
    <p>Bassoon solo: Opus 8 / Opus 11 / Opus 12 / Opus 15 / Opus 21</p>
    <p>Bassoon and piano: Opus 9 / Opus 11 / Opus 14 / Opus 32</p>
    <p>Clarinet solo: Opus 29</p>
    <p>Clarinet and bassoon: Opus 32</p>
    <p>Clarinet and piano: Opus 9 / Opus 24 / Opus 27</p>
    <p>Clarinet / Bassoon and piano: Opus 32 / opus 44</p>
    <p>Clarinet / piano / string quintet and small percussion: Opus 31</p>
    <p>Horn in F and piano: Opus 42</p>
    <p>Bassoon duet or cello bassoon: Opus 2 duet</p>
    <p>Instrumental ensemble: Opus 1</p>
    <p>Instrumental ensemble: Strings / winds / percussions / piano: Opus 46</p>
    <p>Instrumental ensemble: Woodwind quintet / percussions / piano for 4 hands / copper trio / Saxophone E b: Opus 52</p>
    <p>Clarinet / percussion / narrator ensemble: Opus 25</p>
    <p>String and piano ensemble: Opus 34</p>
    <p>Flute solo: Opus 17</p>
    <p>Oboe and piano: Opus 21 / Opus 33</p>
    <p>Oboe / Bb Clarinet / Cello and piano: Opus 32</p>
    <p>Symphony orchestra: Opus 13 / Opus 23 / Opus 26</p>
    <p>Reduced orchestra: Opus 54</p>
    <p>String orchestra and harp: Opus 19</p>
    <p>String and Wind Orchestra: Opus 19</p>
    <p>String orchestra and 5 percussions: Opus 45</p>
    <p>Piano solo: Opus 5 / Opus 6 / Opus 7 / Opus 10 / Opus 15 / opus 37 / Opus 40 / Opus 50 / Opus 53 / Opus 56</p>
    <p>Piano and vocals: Opus 4 / Opus 41</p>
    <p>Piano and Trumpet: Opus 20</p>
    <p>Piano soloist percussion and string orchestra: Opus 50</p>
    <p>String quartet: Opus 32 / Opus 51</p>
    <p>Quartet, Violin / Viola / Cello / Piano: Opus 34</p>
    <p>Quartet, Violin / Viola / Cello / Piano: Opus 34</p>
    <p>Quartet, Cl / Bn / Horn / Piano: Opus 55</p>
    <p>Wind quintet: Opus 18</p>
    <p>Wind quintet and piano: Opus 18 / Opus 52</p>
    <p>Brass quintet: Opus 43 (Deux n °)</p>
    <p>Soprano saxophone: Opus 33</p>
    <p>Tenor saxophone B b: Opus 20</p>
    <p>Reed trio: Opus 2 trio</p>
    <p>Violin solo: Opus 30</p>
    <p>Alto violin and harp: Opus 38</p>
    <p>Violin / cello / Piano trio: Opus 16 / Opus 49</p>
    <p>Trumpet solo: Opus 22</p>
    <p>Cello and piano: Opus 9 / Opus 16 / Opus 32 / Opus 38 / Opus 39</p>
    <p>Cello solo: Opus 15 / Opus 17 / Opus 47</p>
    <p>Octet cl / c / bn / 2v / alt / vlc / cb: Opus 47</p>
    </div>
}

export default catalog;