const tariffs = 
<div className="tariffs-wrapper tariffs-wrapper-phone-landscape" >
<p>Droit pour chaque exécution publique</p>
<p>/</p>
<p>Right for each public performance</p>
<p>Opus 1</p>
<ul>
<li style={{textShadow: "none"}} >le concert des profs : libre de droit</li>
<li style={{textShadow: "none"}} >Musique de scène : 75€</li>
</ul>
<p>Opus 2</p>
<ul>
<li style={{textShadow: "none"}} >Duo : libre de droit</li>
<li style={{textShadow: "none"}} >Trio : 80€</li>
</ul>
<p>Opus 3 : 150€</p>
<p>Opus 4 : 70€</p>
<p>Opus 5 : 120€</p>
<p>Opus 6</p>
<ul>
<li style={{textShadow: "none"}} >Ragtime : 20 €</li>
<li style={{textShadow: "none"}} >Sonatine drolatique : 120€</li>
</ul>
<p>Opus 7</p>
<ul>
<li style={{textShadow: "none"}} >5 petites pièces suaves : 15 €</li>
<li style={{textShadow: "none"}} >8 petites pièces : Libre de droit</li>
<li style={{textShadow: "none"}} >L'infant : Libre de droit</li>
<li style={{textShadow: "none"}} >Spleen : 80€</li>
</ul>
<p>Opus 8 : 25€</p>
<p>Opus 9</p>
<ul>
<li style={{textShadow: "none"}} >Andante et allegro : 70€</li>
<li style={{textShadow: "none"}} >Fantaisie romantique : 70€</li>
<li style={{textShadow: "none"}} >Laconique sonatine : 70€</li>
<li style={{textShadow: "none"}} >Rapsodie fantasque : 80€</li>
</ul>
<p>Opus 10 : 180€</p>
<p>Opus 11</p>
<ul>
<li style={{textShadow: "none"}} >Badinage : Libre de droit</li>
<li style={{textShadow: "none"}} >L'aurore : 65€</li>
</ul>
<p>Opus 12 : 65€</p>
<p>Opus 13 : 900€</p>
<p>Opus 14 : 180€</p>
<p>Opus 15</p>
<ul>
<li style={{textShadow: "none"}} >Improvisation : Libre de droit</li>
<li style={{textShadow: "none"}} >Peregrination 80€</li>
</ul>
<p>Opus 16 : </p>
<ul>
<li style={{textShadow: "none"}} >version 2000 : 250€</li>
<li style={{textShadow: "none"}} >version trio : 300€</li>
<li style={{textShadow: "none"}} >version 2010 : 250€</li>
</ul>
<p>Opus 17 : Astarte ou Ishtar : 80€</p>
<p>Opus 18 : Brocéliande version 2001 ou 2006 : 280€</p>
<p>Opus 19 : </p>
<ul>
<li style={{textShadow: "none"}} >version 2000 : 850€</li>
<li style={{textShadow: "none"}} >version 2008 : 960€</li>
</ul>
<p>Opus 20 : </p>
<ul>
<li style={{textShadow: "none"}} >Figure dans la ville trompette piano : 270€</li>
<li style={{textShadow: "none"}} >Figure dans la ville  piano 4 mains : 280€</li>
<li style={{textShadow: "none"}} >Ivresse 250€</li>
</ul>
<p>Opus 21 : </p>
<ul>
<li style={{textShadow: "none"}} >Largo et presto : 130€</li>
<li style={{textShadow: "none"}} >Rapsodie exotique : libre de droit</li>
</ul>
<p>Opus 22 : 110€</p>
<p>Opus 23 : 1200€</p>
<p>Opus 24 : 55€</p>
<p>Opus 25 : 450€</p>
<p>Opus 26 : 1300€</p>
<p>Opus 27 : 120€</p>
<p>Opus 28 : 850€</p>
<p>Opus 29 : 40€</p>
<p>Opus 30 : 80€</p>
<p>Opus 31 : 300€</p>
<p>Opus 32</p>
<ul>
<li style={{textShadow: "none"}} >2 small danses : 35€</li>
<li style={{textShadow: "none"}} >3 cartes postales : 120€</li>
<li style={{textShadow: "none"}} >Complainte jeux et danse : libre de droit</li>
<li style={{textShadow: "none"}} >Duo : libre de droit</li>
<li style={{textShadow: "none"}} >Duo fantasque : 40€</li>
<li style={{textShadow: "none"}} >Mini quatuor : 80€</li>
<li style={{textShadow: "none"}} >Nostalgie : 80€</li>
</ul>
<p>Opus 33 : Hautbois ou sax soprano : 250€</p>
<p>Opus 34</p>
<ul>
<li style={{textShadow: "none"}} >Version da camera : 350€</li>
<li style={{textShadow: "none"}} >Version quatuor: 200€</li>
</ul>
<p>Opus 35 : 950€</p>
<p>Opus 36 : 200€</p>
<p>Opus 37 : 200€</p>
<p>Opus 38 : version violoncelle ou alto : 110€</p>
<p>Opus 39 : 160€</p>
<p>Opus 40 : 180€</p>
<p>Opus 41 : 100€</p>
<p>Opus 42 : chaque version 120 €</p>
<p>Opus 43 : N°1 ou N°2 : 120 €</p>
<p>Opus 44 : 280 €</p>
<p>Opus 45 : 880 €</p>
<p>Opus 46 : 640 €</p>
<p>Opus 47 : 130 €</p>
<p>Opus 48 : 680 €</p>
<p>Opus 49 : 310 €</p>
<p>Opus 50</p>
<ul>
<li style={{textShadow: "none"}} >Cimaise 6 : 180€</li>
<li style={{textShadow: "none"}} >Fantaisie dégingandée: 750€</li>
</ul>
<p>Opus 51 : 280 €</p>
<p>Opus 52</p>
<ul>
<li style={{textShadow: "none"}} >N°1 : 620€</li>
<li style={{textShadow: "none"}} >N°2 : 370€</li>
</ul>
<p>Opus 53 : 180 €</p>
<p>Opus 54 : 500 €</p>
<p>Opus 55 : 300 €</p>
<p>Opus 56 : 180 €</p>
</div>

export default tariffs;