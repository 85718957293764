const license = {
    fr:
 <div className="license">
<p>Toutes les partitions sur ce site sont libres au téléchargement à titre d'utilisation hors exécution public.</p>
<p>Pour tout concert ou concours un droit d'exécution sera demandé.</p>
<p>Les partitions seront librement dupliqués, mais aucune revente quelqu'en soit la forme ne sera autorisé,</p>
<p>tout commerce autour de la publication de ce matériel musical hors la simple exécution public donnera</p>
<p>lieu à des poursuites, ceci étant valable pour tout pays.</p>
<p>Les droits demandés le seront pour chaque exécution, les droits ne seront pas demandés pour les</p>
<p>répétitions sauf en cas d'entrée libre au public (ne sera nullement inclue les visites des écoles à titre de</p>
<p>sortie scolaire, la visite d'école lors de répétition ou de concert spécifique à la découverte scolaire ne</p>
<p>donnera lieu à aucune demande de droit tant que comme public présent ne seront présent que des</p>
<p>scolaires et leurs accompagnants).</p>
<p>Les arrangements ou transcriptions ne seront autorisés que dans un cadre limité à la facilité d'exécution</p>
<p>ou l'adaptation de formations spécifiques et soumis à l'autorisation des ayants droits, mais ne sera en</p>
<p>aucune manière autorisé pour reprises personnelle ou citation sans autorisation spécifique.</p>
<p>Tout arrangement dénaturant manifestement les œuvres, tant dans sa durée que dans son esprit ne sera</p>
<p>en rien autorisé.</p>
<p>Tout enregistrement audiophonique ou radiophonique ou inclusion dans des films ou reportages</p>
<p>donneront lieux à des négociations spécifiques et soumis à l'autorisation des ayants droits, ceci étant</p>
<p>valable pour tout pays.</p>
</div>,
    en:
    <div className="license">
<p>All the scores on this site are free to download for non-public performance.</p>
<p>For any concert or competition a performing right will be requested.</p>
<p>The partitions will be freely duplicated, but no resale of any kind will be allowed,</p>
<p>any trade around the publication of this musical material apart from the simple public performance will give</p>
<p>result in prosecution, this being valid for any country.</p>
<p>The rights requested will be for each performance, the rights will not be requested for</p>
<p>rehearsals except in the event of free admission to the public (in no way will visits to schools be included as a</p>
<p>school outings, school visits during rehearsals or concerts specific to school discovery</p>
<p>will give rise to no demand for rights as long as the public present will only be present</p>
<p>schoolchildren and their accompanying persons).</p>
<p>Arrangements or transcriptions will only be permitted within a framework limited to ease of performance.</p>
<p>or the adaptation of specific training and subject to the authorization of the beneficiaries, but will not be</p>
<p>no way allowed for personal occasions or quotation without specific permission.</p>
<p>Any arrangement manifestly distorting the works, both in duration and in spirit will not be</p>
<p>in no way allowed.</p>
<p>Any audio or radio recording or inclusion in films or reports</p>
<p>will give rise to specific negotiations and subject to the authorization of the beneficiaries, this being</p>
<p>valid for any country.</p>
</div>
}

export default license;